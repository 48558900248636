<template>
    <SmartForm
        base_url="/settings/temporary-duration-type"
        base_path="/settings/temporary-duration-type"
        form_type="edit"
    ></SmartForm>
</template>

<script>
 import SmartForm from "@/view/components/SmartForm.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartForm,
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipologie Durata Temporanea Società", route: "/settings/temporary-duration-type/index" },
             { title: "Modifica Tipologia Durata Temporanea Società" }
         ]);
     }
 };
</script>
